"use client";
import Image from "next/image";
import Downloads from "@/components/Downloads";

import Hero from "./Hero";
import Gallery from "./Gallery";

export default function Home() {
  return (
    <main className="min-h-screen1 items-center1 justify-between1">
      <div>
        <div>
          <Hero />
        </div>

        <Gallery />
      </div>

      <section className="w-full  bg-gray-50">
        <div className="max-w-screen-xl  mx-auto px-4 flex flex-col justify-center items-center md:py-20 py-10 lg:px-3">
          <h2 className="text-center py-3">Touchpad</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-10 ">
            <div className="">
              <Image
                width={1000}
                height={600}
                src="/images/touch.jpg"
                className="mx-auto"
                alt="Touchpad"
              />
            </div>
            <div className="">
              <div className="py-2">
                <p>
                  The Touchpad is a logical choice for those who want an
                  alternative to the Fingerprint reader.
                </p>
              </div>

              <div className="py-2">
                <p>
                  Add the Touchpad to the Touchkey fingerprint reader or the Bio
                  handle for additional security.
                </p>
              </div>

              <div className="py-2">
                <p>
                  Available in Black and Black + Silver colors, the touchpad
                  connects to the same controller using a Y cable.
                </p>
              </div>
              <div className="py-3">
                <table className="border-collapse border   border-slate-400">
                  <tbody className="">
                    <tr>
                      <td className="border p-2 border-slate-300 ">
                        Number of codes
                      </td>
                      <td className="border p-2 border-slate-300 ">
                        100, of which 1 is an administrator code
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 border-slate-300 ">
                        Length of code
                      </td>
                      <td className="border p-2 border-slate-300 ">
                        4 to 16 characters
                      </td>
                    </tr>
                    <tr>
                      <td className="border p-2 border-slate-300 ">
                        User Interface{" "}
                      </td>
                      <td className="border p-2 border-slate-300 ">
                        Capacitive illuminated keys
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" px-5 bg-slate-800 py-10 md:py-12">
        <div className=" w-full mx-auto py-10 lg:px-32 bg-color-blue-light">
          <h2 className="text-white text-center pb-10">Controllers</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-16 ">
            <div className=" text-white ">
              <div>
                <h5>DIN CONTROL UNIT</h5>

                <p className="p-0">
                  The control unit is mounted in the electrical cabinet.
                </p>
                <h6 className="mb-0 font-bold pt-3">Compatible:</h6>
                <ul>
                  <li>Touchkey fingerprint scanner </li>

                  <li>Biohandle</li>
                  <li>Touchpad</li>
                </ul>
                <h6 className="mb-0 font-bold pt-3">Functions:</h6>
                <ul>
                  <li>Analogue control</li>
                  <li>App control (Wi-Fi direct)</li>
                  <li>2 relay output</li>
                </ul>
                <div className="">
                  <Image
                    width={200}
                    height={180}
                    src="/images/din.png"
                    className=" justify-start"
                    alt="DIN CONTROL UNIT"
                  />
                  <p>CU-303</p>
                </div>
              </div>
            </div>
            <div className="text-white">
              <h5>BBX MINI CONTROL UNIT</h5>

              <p className="p-0">
                The control unit is built into the door leaf, next to the motor
                lock or next to the cable transfer.
              </p>
              <h6 className="mb-0 font-bold pt-3">Compatible:</h6>
              <ul>
                <li>Touchkey fingerprint scanner </li>

                <li>Biohandle</li>
                <li>Touchpad</li>
              </ul>
              <h6 className="mb-0 font-bold pt-3">Functions:</h6>
              <ul>
                <li>Analogue control</li>
                <li>App control (Wi-Fi direct)</li>
                <li>LED connection</li>
                <li>1 relay output</li>
              </ul>
              <div className="">
                <Image
                  width={200}
                  height={180}
                  src="/images/bb.png"
                  className=" justify-start"
                  alt="BBX Mini"
                />
                <p>BBX Mini</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full py-10 p-5">
        <div className=" max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6 align-middle items-center md:text-3xl text-xl font-bold gap-4">
            <div className="flex">
              <div className="self-center py-3 mx-auto">
                <Image
                  height={80}
                  width={80}
                  src="/images/icon-15.png"
                  // className="object-cover h-full"
                  alt="Compatible with all automation Brands."
                />
              </div>
              <div className="pl-10 self-center fontd-bold ">
                Compatible with all automation Brands.
              </div>
            </div>
            <div className="flex">
              <div className="self-center py-2 mx-auto">
                <Image
                  height={80}
                  width={80}
                  src="/images/icon-16.png"
                  // className="h-full object-fill"
                  alt="Compatible with Analog and IP Video doorphones."
                />
              </div>
              <div className=" pl-10 self-center fontd-bold">
                Compatible with Analog and IP Video doorphones.
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-left text-xl lg:text-4xl max-w-xl mx-auto"></div>
      </section>

      <section className="max-w-screen-full mx-auto lg:flex bg-slate-800 py-14">
        <div className="lg:order-2 lg:w-6/12 pt-10  ">
          abd
          <Image
            height={716}
            width={348}
            className="mx-auto max-w-full"
            //src="/images/touchkey-app-control.png"
            src="/images/touchkey-app-control-final-sharp.png"
            alt=" APP CONTROL"
          />
        </div>

        <div className="lg:order-1 container pt-10 max-w-lg mx-auto">
          <div className="align-middle text-white text-3xl font-bold pb-4 pl-5">
            APP CONTROL
          </div>
          <div className="p-5 grid grid-cols-[50px_minmax(100px,_1fr)] text-white align-middle items-center text-xl font-semi-bold gap-4">
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-4.png"
                className="object-cover h-full"
                alt="FINGERPRINT CAPACITIVE: 166X3"
              />
            </div>
            <div>FINGERPRINT CAPACITIVE: 166X3</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-5.png"
                className="object-cover h-full"
                alt="NUMBER OF ADMINISTRATORS: Unlimited"
              />
            </div>
            <div>NUMBER OF ADMINISTRATORS: Unlimited</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-6.png"
                className="object-cover h-full"
                alt="BLUE BLACKLIGHT ON/OFF"
              />
            </div>
            <div>BLUE BLACKLIGHT ON/OFF</div>

            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-7.png"
                className="object-cover h-full"
                alt="SCANNER SECURITY LOCKDOWN"
              />
            </div>
            <div>SCANNER SECURITY LOCKDOWN</div>

            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-8.png"
                className="object-cover h-full"
                alt="LED CONTROL"
              />
            </div>
            <div>LED CONTROL</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-9.png"
                className="object-cover h-full"
                alt="HISTORY OF EVENTS"
              />
            </div>
            <div>HISTORY OF EVENTS</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-10.png"
                className="object-cover h-full"
                alt="RELAY SETUP"
              />
            </div>
            <div>RELAY SETUP</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-11.png"
                className="object-cover h-full"
                alt="ENTRANCE SCHEDULES FOR USERS"
              />
            </div>
            <div>ENTRANCE SCHEDULES FOR USERS</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-12.png"
                className="object-cover h-full"
                alt="FREE PASS MODE"
              />
            </div>
            <div>FREE PASS MODE</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-13.png"
                className="object-cover h-full"
                alt="DATABASE IMPORT AND EXPORT"
              />
            </div>
            <div>DATABASE IMPORT AND EXPORT</div>
            <div className="self-center mx-auto">
              <Image
                height={40}
                width={40}
                src="/images/icon-14.png"
                className="object-cover h-full"
                alt="UNLOCKING THE DOOR VIA SMARTPHONE APP (WITHIN LOCAL REACH)"
              />
            </div>
            <div>
              UNLOCKING THE DOOR VIA SMARTPHONE APP (WITHIN LOCAL REACH)
            </div>
          </div>
        </div>
      </section>
      <section className="self-center max-w-screen-xl mx-auto p-5 py-10">
        <h2 className="text-center pb-4">Product Features And Details</h2>
        <div className=" grid grid-cols-1 md:grid-cols-2 md:gap-20 align-middle items-center gap-4">
          <div>
            <ul className="text-xl lg:text-xl ml-0 font-semibold pl-5 space-y-3 text-gray-800 list-disc marker:text-orange-500 ">
              <li>Capacity: 165 Users, 1 Admin</li>
              <li>
                The controller has 2 relay outputs to control 2 locks
                independently or simultaneously.
              </li>
              <li>
                Can be used for Main door or Sliding / Swing wardrobe doors
              </li>
              <li>
                It is possible to control up to 8 wardrobe doors with one Reader
                and controller.
              </li>
              <li>ID3 algorithm for biometric data processing</li>
              <li>Compatible with all Automation Systems</li>
              <li>Compatible with all video door phones.</li>
              <li>Mounting: Flush Mount with Backbox</li>
            </ul>
          </div>
          <div className="font-bold leading-10">
            <ul className="text-xl lg:text-xl ml-0 font-semibold pl-5 space-y-3 text-gray-800 list-disc marker:text-orange-500 ">
              <li>Push Button Input: Yes (Two Inputs)</li>
              <li>Lock Relay Timing: Programmable (Max 60 sec).</li>
              <li>Backlight Led on reader for feedback.</li>
              <li>Sophisticated sensor for quick fingerprint recognition.</li>
              <li>Fingerprints remain stored in the event of power failure.</li>
              <li>Weekly Scheduling possible.</li>
              <li>Log details are available.</li>
              <li>Control App for IOS & Android Mobile available.</li>
            </ul>
          </div>
        </div>
        <div className="font-bold text-xl py-8">
          <p>
            Aurus has a complete range of locking solutions compatible with
            Touchkey. To know more click on the link below
          </p>
        </div>
      </section>

      <Downloads />
    </main>
  );
}
