import React from "react";
import Image from "next/image";
import { motion } from "framer-motion";
import Link from "next/link";

export const metadata = {
  title: "TouchKey - Sleek Biometric Door Locks & Access Control Solutions",
  description:
    "TouchKey biometrics ensure admission. Bright black or stainless steel reader flush-mounts doors or walls. Fingerprints activate lights and doors. TouchKey biometrics convenience.",
};

const Hero = () => {
  const textVariants = {
    hide: { x: -100, opacity: 0 },
    show: { x: 0, opacity: 1, transition: { duration: 3 } },
  };
  // const containerVariants = {
  //   hidden: { opacity: 0, y: 20 },
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       when: "beforeChildren",
  //       staggerChildren: 0.3,
  //     },
  //   },
  // };

  // const itemVariants = {
  //   hidden: { opacity: 0, y: 20 },
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       type: "spring",
  //       stiffness: 100,
  //     },
  //   },
  // };

  return (
    <>
      <section className="w-full mx-auto text-center lg:px-32 bg-color-blue-light relative">
        <Image
          src="/images/md2.jpg"
          alt=""
          className="object-cover w-full h-full absolute -z-10"
          fill
          priority={true}
        />
        <div className="pt-64 md:pt-[600px] pb-8 align-baseline  max-w-screen-xl mx-auto ">
          <h2 className="font-semibold">
            A smart home begins with a smart entrance
          </h2>
        </div>
      </section>

      {/* <section
        style={{
          backgroundImage: `url(
              "images/md2.jpg" 
              )`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="w-full mx-auto  text-center lg:px-32 bg-color-blue-light"
      >
        <div className="py-72 md:py-96 max-w-screen-xl mx-auto "></div>
      </section> */}

      <div
        className="slider-sec py-10 md:py-16 min-h-screeen w-full mx-auto"
        style={{ backgroundImage: 'url("../images/s1.jpg")' }}
      >
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-6">
            <div className="text-center">
              <h3 className="text-black heading text-center font-bold">
                Touchkey Fingerprint Reader
              </h3>

              <Image
                width={1400}
                height={1200}
                src="/images/key1.png"
                className="mx-auto"
              />
              <button className=" mt-5 bg-orange-500 px-10 font-medium py-3 rounded-full">
                <Link
                  className="text-white"
                  href="/touchkey-fingerprint-reader"
                >
                  Know More
                </Link>
              </button>
            </div>
            <div className="text-center ">
              <h3 className="text-black heading text-center font-bold">
                Biohandle
              </h3>

              <Image
                width={1400}
                height={1200}
                src="/images/hand.png"
                className="mx-auto"
              />
              <button className="mt-5 bg-orange-500 px-10 font-medium py-3 rounded-full">
                <Link className="text-white" href="/biohandle">
                  Know More
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
