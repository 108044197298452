import React from "react";
import { HiDocumentText } from "react-icons/hi";
import { FaGlobe } from "react-icons/fa";
import { FaVideo } from "react-icons/fa";

const Downloads = () => {
  return (
    <>
      <div id="download" className=" bg-white text-center">
        <h3>Downloads & Links</h3>
        <div className="text-center lg:flex justify-center py-10 px-10 lg:pl-16">
          <button className="custom-btn btn-7 mr-4 rounded-xl text-base md:text-lg mb-4">
            <a
              href="/downloads/Touchkey-2024-DLR.pdf"
              target="_blank"
              className="flex font-medium"
            >
              <HiDocumentText className="w-8 h-8 mr-2" />{" "}
              <span className="pt-1">Download Brochure</span>
            </a>
          </button>

          <button className="custom-btn btn-7 mr-4 rounded-xl text-base md:text-lg mb-4">
            <a
              href="/downloads/Touchkey-download-manual.pdf"
              target="_blank"
              className="flex font-medium"
            >
              <HiDocumentText className="w-8 h-8 mr-2" />{" "}
              <span className="pt-1">Download Manual</span>
            </a>
          </button>

          <button className="custom-btn btn-7 btn-7-text-blue mr-4 rounded-xl text-base md:text-lg mb-4">
            <a
              href="https://auruslocks.com/product/electric-locks-and-accessories/"
              target="_blank"
              className="flex font-medium"
            >
              <FaGlobe className="w-7 h-7 mr-1" />
              <span className="pt-0">Aurus Locking Solutions</span>
            </a>
          </button>

          <button className="custom-btn btn-7 btn-7-text-blue mr-4 rounded-xl text-base md:text-lg mb-4">
            <a href="/videos" target="_blank" className="flex font-medium">
              <FaVideo className="w-8 h-8 mr-2" />{" "}
              <span className="pt-1">Watch Videos</span>
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Downloads;
